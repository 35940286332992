import { z } from 'zod';
import { endOfDay } from 'date-fns';
import { formatDate } from '../dateHelper';
import { getPolicyMaxDate, getPolicyMinDate } from '../policyHelpers';

const validateMinDateFrom = (dateFrom: Date | null, ctx: z.RefinementCtx) => {
  const minDate = getPolicyMinDate();
  if (dateFrom && dateFrom < minDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['dateFrom'],
      message: `Min date is ${formatDate(minDate)}`,
    });
  }
};

const validateMaxDateFrom = (dateFrom: Date | null, ctx: z.RefinementCtx) => {
  const maxDate = getPolicyMaxDate();
  if (dateFrom && dateFrom > maxDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['dateFrom'],
      message: `Max date is ${formatDate(maxDate)}`,
    });
  }
};

const validateMinDateTo = (
  { dateFrom, dateTo }: { dateFrom: Date | null; dateTo: Date | null },
  ctx: z.RefinementCtx,
) => {
  const minDate =
    dateFrom && dateFrom > getPolicyMinDate() ? dateFrom : getPolicyMinDate();
  if (dateTo && dateTo < minDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['dateTo'],
      message: `Min date is ${formatDate(minDate)}`,
    });
  }
};

const validateMaxDateTo = (dateTo: Date | null, ctx: z.RefinementCtx) => {
  const maxDate = getPolicyMaxDate();

  if (dateTo && endOfDay(dateTo) > endOfDay(maxDate)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['dateTo'],
      message: `Max date is ${formatDate(maxDate)}`,
    });
  }
};

export function validatePolicyDateRange(
  { dateFrom, dateTo }: { dateFrom: Date | null; dateTo: Date | null },
  ctx: z.RefinementCtx,
) {
  validateMinDateFrom(dateFrom, ctx);
  validateMaxDateFrom(dateFrom, ctx);
  validateMinDateTo({ dateFrom, dateTo }, ctx);
  validateMaxDateTo(dateTo, ctx);
}

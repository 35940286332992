import { ApiError } from '@/api/support';

export const GENERAL_ERROR_MSG = 'Something went wrong. Please try again later on.';

const isApiError = (error: unknown): error is ApiError => !!(error as ApiError)?.body;

export const getApiErrorMessage = (error: ApiError | unknown) => {
  if (!isApiError(error)) {
    return GENERAL_ERROR_MSG;
  }

  return error.status !== 500 && error.body.length ? error.body : GENERAL_ERROR_MSG;
};

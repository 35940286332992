import { MenuItem } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { OptionValueDto } from '@/api/retail/v2';
import { YesNo } from '@/models/YesNo';

export const commonSx = { my: 3, width: '100%' };
export const maxWidthSx = { maxWidth: '65%' };

export type OptionCode = {
  description?: string | null;
  code?: string | number | null;
};

const sortByName = (array: [string, string][]): [string, string][] =>
  array.sort((a, b) => {
    const [, nameA] = a;
    const [, nameB] = b;
    return nameA.localeCompare(nameB);
  });

export const renderSelectOptionsByEnum = <
  TValue extends string,
  TEnum extends Record<string, TValue>,
>(
  type: TEnum,
  getName?: (t: TValue) => string,
  options?: {
    include?: NoInfer<TValue>[];
    exclude?: NoInfer<TValue>[];
    sort?: boolean;
  },
) => {
  const nameValues: [TValue, string][] = Object.values(type)
    .filter(value => !options?.include || options.include.includes(value))
    .filter(value => !options?.exclude?.includes(value))
    .map(value => [value, getName ? getName(value) : value]);

  const sortedNameValues = options?.sort ? sortByName(nameValues) : nameValues;

  return sortedNameValues.map(([value, name]) => (
    <MenuItem key={value} value={value}>
      {name}
    </MenuItem>
  ));
};

export const renderSelectOptionsByOptionsCodes = (
  options: (OptionCode & OptionValueDto)[],
) =>
  options.map(({ code, description, value }) => {
    if ((code == null && value == null) || description == null) return null;

    return (
      <MenuItem key={code ?? value} value={code ?? value}>
        {description}
      </MenuItem>
    );
  });

export const renderSelectOptionsByName = (options: (string | number)[]) =>
  options.map(value => (
    <MenuItem key={value} value={value}>
      {value}
    </MenuItem>
  ));

export const getErrorProps = (fieldError: FieldError | undefined) => ({
  error: Boolean(fieldError),
  helperText: fieldError?.message,
});

export const hasAnsweredYes = (answer: YesNo | null) => answer === YesNo.Yes;

export const getDirtyFields = <
  TDirtyItems extends Record<string, unknown>,
  TData extends Record<keyof TDirtyItems, unknown>,
>(
  formValues?: TData,
  dirtyItems?: TDirtyItems,
): Partial<TData> => {
  return Object.entries(dirtyItems ?? {}).reduce((dirtyData, [key, value]) => {
    if (value === false) return dirtyData;
    if (value === true) return { ...dirtyData, [key]: true };

    const child = getDirtyFields(
      formValues?.[key] as TData,
      dirtyItems?.[key] as TDirtyItems,
    );

    if (typeof child === 'object' && Object.keys(child).length === 0) {
      return dirtyData;
    }

    if (Array.isArray(child) && child.length === 0) {
      return dirtyData;
    }

    return {
      ...dirtyData,
      [key]: child,
    };
  }, {});
};

export function stopPropagate(
  callback: (event: React.FormEvent<HTMLFormElement>) => void,
) {
  return (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    callback(event);
  };
}

export const overrideReactHookFormRef = {
  ref: (ref: HTMLElement | null) => ref,
};

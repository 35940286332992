import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { queryKeys } from '@/helpers/queryKeys';
import { GetRemindersResponseDto, ReminderFilterType } from '@/api/note';
import { ReminderDetail } from '@/models/ReminderDetail';
import { sortByDateAsc } from '@/helpers/arrayHelpers';

type RemindersParams = { filterType: ReminderFilterType };

export function useIndividualRemindersQuery(filters: RemindersParams) {
  const api = useApiClient();

  const fetchIndividualReminders = () => api.note.notes.getApiV1NotesReminders(filters);
  const selectIndividualReminders = (response: GetRemindersResponseDto) =>
    sortByDateAsc(response.remindersDetails ?? [], 'dateOfReminder') as ReminderDetail[];

  const query = useQuery({
    queryKey: queryKeys.individualReminders(filters),
    queryFn: fetchIndividualReminders,
    select: selectIndividualReminders,
  });

  return {
    ...query,
    reminders: query.data ?? [],
  };
}

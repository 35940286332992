import { format, formatISO, isEqual, parseISO } from 'date-fns';
import { DateOnly } from '@/models/DateOnly';

const extractDateOnly = (dateOnly: DateOnly) => dateOnly.split('-').map(x => +x);

export function getDate() {
  return new Date();
}

export function areNullableDatesEqual(date1: Date | null, date2: Date | null): boolean {
  if (date1 instanceof Date && date2 instanceof Date) {
    return isEqual(date1, date2);
  }

  return date1 === date2;
}

export function formatDate(date: string | Date): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function formatTime(date: string | Date): string {
  return format(new Date(date), 'HH:mm');
}

export function getDateOnly(date: Date): DateOnly {
  return formatISO(date, { representation: 'date' });
}

export function formatDateTime(date: string | Date): string {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function parseDateOnly(dateOnly: DateOnly): Date {
  const [year, month, day] = extractDateOnly(dateOnly);
  return new Date(year, month - 1, day);
}

export function formatDateOnly(dateOnly: DateOnly): string {
  const date = parseDateOnly(dateOnly);
  return formatDate(date);
}

export const getDateWithOffset = (date: Date) => {
  const parsed = parseISO(date.toISOString());
  return format(parsed, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx");
};

import { useController, useFormContext } from 'react-hook-form';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  type TextFieldProps,
} from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledFormTextFieldBasic } from './FormTextField.styles';
import { FieldError } from './components/FieldError';
import { getErrorProps, overrideReactHookFormRef } from '@/helpers/formHelper';
import { pipe } from '@/helpers/pipe';

type DisplayStyle = 'default' | 'basic';

type Transformer = (value: string) => string;

type FormTextFieldProps = {
  name: string;
  testId?: string;
  loading?: boolean;
  displayStyle?: DisplayStyle;
  transformers?: Transformer[];
} & TextFieldProps &
  PropsWithChildren;

function getLoadingProps(): TextFieldProps {
  return {
    disabled: true,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <CircularProgress size={20} />
        </InputAdornment>
      ),
    },
    SelectProps: {
      IconComponent: () => null,
    },
  };
}

export function FormTextField({
  name,
  loading = false,
  testId,
  children,
  displayStyle = 'default',
  transformers,
  ...props
}: FormTextFieldProps) {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  const loadingProps = loading ? getLoadingProps() : undefined;
  const inputProps = testId
    ? { 'data-testid': testId, ...props.inputProps }
    : props.inputProps;

  const fieldValue = (field.value as number | string | null | undefined) ?? '';

  const transformedValue = transformers
    ? // @ts-expect-error A spread argument must either have a tuple type or be passed to a rest parameter.ts(2556)
      pipe(fieldValue, ...transformers)
    : fieldValue;

  if (displayStyle === 'basic') {
    return (
      <Stack direction="column" gap={2} sx={props.sx}>
        <Typography component="label" variant="body2">
          {props.label}
        </Typography>
        <Stack direction="column" gap={1}>
          <StyledFormTextFieldBasic
            $isError={!!error}
            onChange={field.onChange}
            name={field.name}
            value={transformedValue}
          />
          {error?.message ? <FieldError>{error.message}</FieldError> : null}
        </Stack>
      </Stack>
    );
  }

  return (
    <TextField
      {...getErrorProps(error)}
      {...field}
      {...props}
      {...loadingProps}
      {...overrideReactHookFormRef}
      value={transformedValue}
      inputProps={inputProps}
    >
      {children}
    </TextField>
  );
}

import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from '@mui/material';

export const StyledDrawerContent = styled(Box)(({ theme }) => ({
  boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.10)',
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  paddingTop: theme.spacing(16),
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledDrawerHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  position: 'sticky',
  zIndex: theme.zIndex.drawer,
  top: 0,
}));

export const StyledDrawerTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightBold,
}));

export const StyledDrawerMenuList = styled(List)(({ theme }) => ({
  width: '210px',
  height: '100vh',
  minWidth: '210px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: `${theme.spacing(25)} ${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(
    6,
  )}`,
  gap: theme.spacing(2),
  overflow: 'auto',
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '160px',
  height: '40px',
  maxHeight: '40px',
  display: 'flex',
  borderRadius: theme.spacing(2),
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  color: theme.palette.text.primary,

  '&:hover, &.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.light,
  },
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    padding: theme.spacing(0),
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 'fit-content',
  color: 'inherit',
}));

export const StyledDrawerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '975px',
  maxWidth: 'calc(100vw - 80px)',
  height: '100vh',
  backgroundColor: theme.palette.background.paper,
}));

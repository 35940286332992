/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_LockDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_LockDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_MotorPolicyDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_MotorPolicyDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CancelMotorPolicyRequestDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CancelMotorPolicyRequestDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_GetMotorPolicyWithAuditResponseDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_GetMotorPolicyWithAuditResponseDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyRequestDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyRequestDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyResponseDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyResponseDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_UpdatePolicyholderAccountRequestDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_UpdatePolicyholderAccountRequestDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CancelMotorPolicyResponseDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CancelMotorPolicyResponseDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_DebtDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_DebtDto';
import type { Dayinsure_Service_MotorPolicy_Domain_Enums_PolicyStatus } from '../models/Dayinsure_Service_MotorPolicy_Domain_Enums_PolicyStatus';
import type { Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto } from '../models/Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MotorPolicyService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_MotorPolicyDto Success
   * @throws ApiError
   */
  public getApiV1Motorpolicies({
    motorQuoteId,
    status,
    skipFutureVersion = true,
  }: {
    motorQuoteId?: string,
    status?: Array<Dayinsure_Service_MotorPolicy_Domain_Enums_PolicyStatus>,
    skipFutureVersion?: boolean,
  }): CancelablePromise<Array<Dayinsure_Common_Contracts_MotorPolicy_Dtos_MotorPolicyDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies',
      query: {
        'motorQuoteId': motorQuoteId,
        'status': status,
        'skipFutureVersion': skipFutureVersion,
      },
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_GetMotorPolicyWithAuditResponseDto Success
   * @throws ApiError
   */
  public getApiV1Motorpolicies1({
    id,
    fetchAuditMinutes,
    policyVersion,
    skipFutureVersion = true,
  }: {
    id: string,
    fetchAuditMinutes?: number,
    policyVersion?: number,
    skipFutureVersion?: boolean,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_GetMotorPolicyWithAuditResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{id}',
      path: {
        'id': id,
      },
      query: {
        'fetchAuditMinutes': fetchAuditMinutes,
        'policyVersion': policyVersion,
        'skipFutureVersion': skipFutureVersion,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesLock({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: Dayinsure_Common_Contracts_MotorPolicy_Dtos_LockDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/lock',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesUnlock({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/unlock',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CancelMotorPolicyResponseDto Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesCancellationMotorQuote({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CancelMotorPolicyRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CancelMotorPolicyResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/cancellation-motor-quote',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesCancel({
    id,
    cancellationQuoteId,
    bypassPayment,
  }: {
    id: string,
    cancellationQuoteId?: string,
    bypassPayment?: boolean,
  }): CancelablePromise<Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/cancel',
      path: {
        'id': id,
      },
      query: {
        'cancellationQuoteId': cancellationQuoteId,
        'bypassPayment': bypassPayment,
      },
      errors: {
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyResponseDto Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesTransactionReversalQuote({
    policyId,
    transactionId,
    requestBody,
  }: {
    policyId: string,
    transactionId: string,
    requestBody?: Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_ReversalQuoteMotorPolicyResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{policyId}/transaction/{transactionId}/reversal-quote',
      path: {
        'policyId': policyId,
        'transactionId': transactionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesTransactionReverse({
    policyId,
    transactionId,
    reversalQuoteId,
    bypassPayment,
  }: {
    policyId: string,
    transactionId: string,
    reversalQuoteId?: string,
    bypassPayment?: boolean,
  }): CancelablePromise<Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{policyId}/transaction/{transactionId}/reverse',
      path: {
        'policyId': policyId,
        'transactionId': transactionId,
      },
      query: {
        'reversalQuoteId': reversalQuoteId,
        'bypassPayment': bypassPayment,
      },
      errors: {
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto Success
   * @throws ApiError
   */
  public postApiV1MotorpoliciesAdjust({
    id,
    mtaQuoteId,
    bypassPayment,
  }: {
    id: string,
    mtaQuoteId?: string,
    bypassPayment?: boolean,
  }): CancelablePromise<Dayinsure_Service_MotorPolicy_Web_Dtos_PaymentRequiredResposneDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/adjust',
      path: {
        'id': id,
      },
      query: {
        'mtaQuoteId': mtaQuoteId,
        'bypassPayment': bypassPayment,
      },
      errors: {
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_DebtDto Success
   * @throws ApiError
   */
  public getApiV1MotorpoliciesDebt({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_DebtDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{id}/debt',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public getApiV1MotorpoliciesMtas({
    policyId,
  }: {
    policyId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{policyId}/mtas',
      path: {
        'policyId': policyId,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public getApiV1MotorpoliciesDocuments({
    id,
    policyVersion,
    skipFutureVersion = true,
  }: {
    id: string,
    policyVersion?: number,
    skipFutureVersion?: boolean,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{id}/documents',
      path: {
        'id': id,
      },
      query: {
        'policyVersion': policyVersion,
        'skipFutureVersion': skipFutureVersion,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public getApiV1MotorpoliciesAudit({
    policyId,
  }: {
    policyId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motorpolicies/{policyId}/audit',
      path: {
        'policyId': policyId,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public postApiV1MotorpoliciesAccountUpdate({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_UpdatePolicyholderAccountRequestDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motorpolicies/{id}/account-update',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        409: `Conflict`,
        422: `Client Error`,
      },
    });
  }
}
